body {
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    background:url('./img/bggg.png');
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-size: cover; 
    background-position: left; 
}
/* .totalmain{
    overflow-y: hidden;
    height: fit-content;
    width:screen;
    padding:0;
    margin:0;

} */
.reffcode{
    font-size: 1.1rem;
    padding:7px;
}
.admin{
     overflow-y: visible;
     overflow-x: auto;
}
.other-content{
    display: flex;
}
.signup-options{
    display: flex;
    justify-content: end;
    margin-right: 6%;
}
.referal-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.star {
    position: absolute;
    width: 1px;
    height: 1px;
    background: white;
    border-radius: 50%;
    animation: twinkling 10s infinite;
  }
  
  @keyframes twinkling {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0;}
  }

.viewport {
    position:relative;
    width:screen;
    height:screen;
}
.moveX1,.moveX2,.moveX3{
  position:absolute;
  /* background:#f00; */
}
.moveX1{
  height:2px;
  width:180px;
  top:100vh;
  left:-40vh;
  -webkit-animation: mX1 3s ease-in-out 0s infinite;
   animation: mX1 30s ease-in-out 0s infinite;
}
.moveX2{
  height:2px;
  width:400px;
  top:100vh;
  left:-70vh;
  -webkit-animation: mX2 8s ease-in-out 0s infinite;
   animation: mX2 30s ease-in-out 0s infinite;
}
.moveX3{
  height:2px;
  width:400px;
  top:100vh;
  left:-90vh;
  -webkit-animation: mX3 8s ease-in-out 0s infinite;
   animation: mX3 30s ease-in-out 0s infinite;
}
.moveY1,.moveY2,.moveY3{
    position:absolute;
    /* border:1px solid #333; */
}
.moveY1{
    width:700px;
    height:50px; 
    top:-25px;
    -webkit-animation: mO 10s linear 0s infinite;
    animation: mO 40s linear 0s infinite;
}
.moveY2{
    width:1200px;
    height:100px; 
    top:-50px;
    -webkit-animation: mO 15s linear 0s infinite;
    animation: mO 30s linear 0s infinite;
}
.moveY3{
  width:1700px;
  height:100px; 
  top:-75px;
  -webkit-animation: mO 25s linear 0s infinite;
    animation: mO 20s linear 0s infinite;
}
.elipsoid {  
  position:relative;
  background:url('http://placehold.it/100/00f/fff/&text=>°))><');
  top: 0px;
  left: 0px;
  width: 100px;
  height: 100px;
  border-radius:50%;
}
.element { 
position: absolute; 
top: 10px; 
right: 10px; 
} 
@keyframes mO {
    0% { transform: rotate(0deg);   }
  100% { transform: rotate(360deg); }
}
@-webkit-keyframes mO {
    0% { -webkit-transform: rotate(0deg);   }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes mX1 {
    0% { transform: translateX(0px);   }
   50% { transform: translateX(150px); }
  100% { transform: translateX(0px);   }
}
@keyframes mX2 {
    0% { transform: translateX(0px);   }
   50% { transform: translateX(200px); }
  100% { transform: translateX(0px);   }
}
@keyframes mX3 {
    0% { transform: translateX(0px);   }
   50% { transform: translateX(210px); }
  100% { transform: translateX(0px);   }
}
@keyframes mX {
    0% { transform: translateX(0px);   }
   50% { transform: translateX(320px); }
  100% { transform: translateX(0px);   }
}
@-webkit-keyframes mX1 {
    0% { -webkit-transform: translateX(0px)    }
   50% { -webkit-transform: translateX(150px); }
  100% { -webkit-transform: translateX(0px)    }
}
@-webkit-keyframes mX2 {
    0% { -webkit-transform: translateX(0px)    }
   50% { -webkit-transform: translateX(200px); }
  100% { -webkit-transform: translateX(0px)    }
}
@-webkit-keyframes mX3 {
    0% { -webkit-transform: translateX(0px)    }
   50% { -webkit-transform: translateX(210px); }
  100% { -webkit-transform: translateX(0px)    }
}
@-webkit-keyframes mX {
    0% { -webkit-transform: translateX(0px)    }
   50% { -webkit-transform: translateX(320px); }
  100% { -webkit-transform: translateX(0px)    }
}

.element img{
    height: 275px;
    width:275px;
  }

header{
    width: 100%;
    height:6vh;
    z-index: 1;
}
.header1{
    position:fixed;
}
.header1 h1{
    margin-left: 1vw;
}
.rectangle {
    width: 30%;
    height: 90vh;
    margin-top: 2vh;
    flex-shrink: 0;
    min-width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
    overflow: auto;
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.56);
    background: linear-gradient(86deg, rgba(255, 255, 255, 0.05) 11.14%, rgba(255, 255, 255, 0.03) 113.29%);
    box-shadow: 0px 1.197px 29.915px 0px rgba(14, 36, 113, 0.02);
    backdrop-filter: blur(35px);
  }
.rect {
    width: 40%;
    height: 72%;
    flex-shrink: 0;
    border-radius: 50px;
    min-width: 400px;
    border: 2px solid #FFF;
    background: rgba(0, 22, 53, 0.05);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    overflow: auto;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(10px);
}
.reviewrect {
    width: 50vw;
    height: auto;
    margin: 2vh auto 5vh auto;
    flex-shrink: 0;
    border-radius: 50px;
    max-width: 900px;
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    padding: 5vh 2vw;
    border: 1px solid rgba(255, 255, 255, 0.56);
    background: linear-gradient(86deg, rgba(255, 255, 255, 0.05) 11.14%, rgba(255, 255, 255, 0.03) 113.29%);
    box-shadow: 0px 1.197px 29.915px 0px rgba(14, 36, 113, 0.02);
    backdrop-filter: blur(35px);
}

.reviewrect2 {
    width: 40vw;
    height: auto;
    margin: 8vh auto 5vh auto;
    flex-shrink: 0;
    border-radius: 50px;
    max-width: 900px;
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
    padding: 4vh 2vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1.5px solid #FFF;
    background: rgba(114, 114, 114, 0.20);
    box-shadow: 0px 1.197px 29.915px 0px rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(8.899999618530273px);
}

.reviewrect2:hover{
    background: linear-gradient(102deg, rgba(255, 255, 255, 0.349) 0.11%, rgba(255, 255, 255, 0.296) 99.89%);
    cursor:pointer;
}

.reviewdesc{
    text-align: left;
    margin-left: 1vw;
}

.reviewdesc h1{
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 2vw;
    font-style: normal;
    font-weight: 900;
    line-height: 0.3;
    letter-spacing: -1.024px;
}
.reviewdesc h2{
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 1.5vw;
    font-style: normal;
    font-weight: 300;
    line-height: 0.7;
    letter-spacing: -0.316px;
    margin-bottom: 20%;
}
.reviewdesc h3{
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 500;
    line-height: 0.3;
    letter-spacing: -0.432px;
}
.reviewdesc h4{
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.346px;
}
.arrow{
    display: flex;
    align-items: center;
    margin-right: 2vw;
    margin-left: 0; 
    transition: margin-left 0.5s ease;
}
.arrow img{
    height:8vh;
}
.container1 {
    display: flex;
    flex-direction: column;
}
.left-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}
.teambox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin:auto 3vw;
}
.teamnamebox {
    display: flex;
    justify-content: center;
    height:auto;
    width:28vw;
    border-radius: 20px;
    border: 2px solid var(--main-card-border, rgba(255, 255, 255, 0.20));
    background: rgba(114, 114, 114, 0.20);
    box-shadow: 14px 16px 21px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    margin-bottom: 5%;
}
.teamdetailbox {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: auto;
    width: 28vw;
    border-radius: 20px;
    padding:10% auto;
    border: 2px solid var(--main-card-border, rgba(255, 255, 255, 0.20));
    background: rgba(114, 114, 114, 0.20);
    box-shadow: 14px 16px 21px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
}
.teamnamebox h1{
    color: #FFF;
    font-family: Michroma-Regular;
    font-size: 200%;
    font-style: normal;
    font-weight: 400;
    line-height: 0.5;
    letter-spacing: 0.8px;
}
.teammembox{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height:auto;
    width:80%;
    color:white;
    margin: 2% auto;
    border-radius: 80px;
    background: rgba(0, 0, 0, 0.20);
    box-shadow: 0px 0px 17.4px 6px rgba(0, 0, 0, 0.25) inset, 33.6px 38.4px 50.4px 0px rgba(38, 38, 38, 0.15);
}

.teammembox1{
    margin-top:4%;
}
.teammembox2{
    margin-bottom:4%;
}
.logo{
    display:flex;
    justify-items: flex-start;
    align-items: center;
    margin-right:10%;
}
.logo img{
    height: 5vh;
}
.teammembox h1{
    color: #FFF;
    font-family: Poppins;
    font-size: 3vh;
    font-style: normal;
    font-weight: 500;
    line-height: 0.5;
    letter-spacing: 0.64px;
}
.teammembox h2{
    color: #FFF;
    font-family: Poppins;
    font-size: 3vh;
    font-style: normal;
    font-weight: 300;
    line-height: 0.5;
    letter-spacing: 0.64px;
}
.teamlink {
    color: #0076B3;
    text-align: left;
    font-family: Poppins, sans-serif;
    font-size: 2.9vh;
    cursor: pointer;
    margin-top: 1vh;
    margin-bottom: 2vh;
    font-weight: 900;
}
.right-box {
    width: 60%; 
    padding: 1vh 4vw;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    
  }
  
.review-table {
    width: 80%;
    border: #FFF solid;
    margin: 7vh auto 5vh auto;
}
  
.review-table td {
    color: #FFF;
    padding: 2px;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 2.4vh;
    font-weight: 200;
    border: #FFF solid;
}
  
.review-table th {
    color: #FFF;
    padding: 8px;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 2.7vh;
    font-weight: 800;
    border-bottom: 2px solid white;
}

.button4 {
    background-color: #27A5EF;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.3vw;
    cursor: pointer;
    width:50%;
    min-width:70px;
    height: 5.5vh;
    flex-shrink: 0; 
    font-family: 'Baloo Da',sans-serif; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center; 
    display: flex; 
    align-items: center;
    justify-content: center;
}

.rect2 {
    width: 36%;
    height: 63%;
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    overflow: auto;
    min-height: 250px;
    min-width: 250px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.56);
    background: linear-gradient(86deg, rgba(255, 255, 255, 0.05) 11.14%, rgba(255, 255, 255, 0.03) 113.29%);
    box-shadow: 0px 1.197px 29.915px 0px rgba(14, 36, 113, 0.02);
    backdrop-filter: blur(35px);
    padding:30px;
}
.rect2 h1 {
    display: flex;
    justify-content: center;
    align-items:flex-start;
}

.rect3 {
    width: 30%;
    height: 80vh;
    flex-shrink: 0;
    min-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    color: white;
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.56);
    background: linear-gradient(86deg, rgba(255, 255, 255, 0.05) 11.14%, rgba(255, 255, 255, 0.03) 113.29%);
    box-shadow: 0px 1.197px 29.915px 0px rgba(14, 36, 113, 0.02);
    backdrop-filter: blur(35px);
}

.rect4 {
    width: 90%;
    height: auto;
    margin: 2vh auto 5vh auto;
    flex-shrink: 0;
    max-width: 650px;
    position: relative;
    text-align: left;
    color: white;
    overflow: hidden;
    padding-bottom: 2vh;
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.56);
    background: linear-gradient(86deg, rgba(255, 255, 255, 0.05) 11.14%, rgba(255, 255, 255, 0.03) 113.29%);
    box-shadow: 0px 1.197px 29.915px 0px rgba(14, 36, 113, 0.02);
    backdrop-filter: blur(35px);
}

.rect5 {
    width: 60vw;
    max-width: 1050px;
    height: auto;
    margin: 2vh auto 5vh auto;
    border-radius: 50px;
    border: 2px solid #FFF;
    background: rgba(0, 22, 53, 0.05);
    position: relative;
    text-align: left;
    color: white;
    overflow: hidden;
    padding-bottom: 5vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
}
.rect66{
    width: 40%;
    height: fit-content;
    margin: -5vh auto 0vh auto;
    flex-shrink: 0;
    min-width: 500px;
    border-radius: 50px;
    border: 2px solid #FFF;
    background: rgba(0, 22, 53, 0.05);
    position: relative;
    text-align: center;
    color: white;
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
}
.rect6 {
    width: 40%;
    height: auto;
    margin: 2vh auto 3vh auto;
    flex-shrink: 0;
    min-width: 500px;
    border-radius: 50px;
    border: 2px solid #FFF;
    background: rgba(0, 22, 53, 0.05);
    position: relative;
    text-align: center;
    color: white;
    overflow:hidden;
    padding-top: 3vh;
    padding-bottom: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
}
.rect7 {
    padding-left: 4vw;
    padding-right: 4vw;
}
.rect3 h1{
    margin-left: 6vw;
}
.card{
    height:40vh;
    width:75%;
    border-radius: 45px;
    margin: 3vh auto;
    background-color: #09182E;
    display: flex;
    padding:1.5vh 0.5vw;
    display: flex;
    justify-content:space-around;
    align-items: center;
    flex-direction: row;
}
.teamdetails{
    height: fit-content;
    width: fit-content;
}
.memdetails{
    height: fit-content;
    width: fit-content;
}
.cardimg{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
    max-height:200px;
    max-width: 200px;
}
.teammem {
    color: #FFF;
    font-family: 'Baloo Da', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
    margin-left: 40%;
    text-align: center;
}
.teamname {
    color: #FFF;
    font-family: Michroma-Regular, sans-serif;
    font-size:4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin:9vh 0 5vh 0;
}
.teamdetails{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 1vw;
}
.memname {
    color: #FFF;
    text-align: center;
    font-family: Baloo Da, sans-serif;
    font-size: 5.5vh;
    font-weight: 400;
    margin: 1.5vh auto 1vh auto;
}
.detailname {
    color: #FFF;
    text-align: left;
    font-family: Poppins, sans-serif;
    font-size: 2.5vh;
    font-weight: bolder;
}
.detailval {
    color: #FFF;
    text-align: left;
    font-family: Poppins, sans-serif;
    font-size: 2.5vh;
    font-weight:lighter;
    margin-bottom:2vh;
}
.sign-up-text {
    color: #FFF;
    font-family: Michroma-Regular, sans-serif;
    font-size: 4.5vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 4.5vh;
    margin-left:6.3vw;
}
.sign-in-text {
    color: #FFF;
    font-family: Michroma-Regular, sans-serif;
    font-size: 6vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 5vh 0 5vh 0;
}
.reviewtxt {
    color: #FFF;
    font-family: Michroma-Regular, sans-serif;
    font-size: 3.2vw;
    font-style: normal;
    font-weight: 700;
    line-height: 0.3;
    letter-spacing: 1.28px;
}
.reviewtime{
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 250;
    letter-spacing: 0.6;
}
.join-text {
    color: #FFF;
    font-family: Michroma-Regular, sans-serif;
    font-size: 6vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
}
.reg-success-text {
    color: #FFF;
    font-family: Michroma-Regular, sans-serif;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 8% 5% 0 5%;
}
.errortext {
    color: #FFF;
    font-family: Michroma-Regular, sans-serif;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 16% 0 2vh 0;
}
.errormsg {
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    margin: auto 0;
}
.success-box {
    width: 18vw; /* Adjust width as needed */
    min-width: 250px; /* Set a minimum width */
    height: 7vh;
    margin: 6vh 0 4vh 0;
    border-radius: 10px;
    border: 1px dashed var(--glass-card-border, rgba(255, 255, 255, 0.40));
    background: rgba(83, 83, 83, 0.27);
    box-shadow: 42px 48px 63px 0px rgba(38, 38, 38, 0.15);
    backdrop-filter: blur(33.650001525878906px);
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribute items evenly */
    padding: 3px; /* Add some padding */
}

.referral-code-container {
    flex-grow: 1; 
    text-align: center;
    padding-top: 12%;
    padding-bottom: 5%;
}

.copy-button {
    
    background: none;
    border: none;
    cursor: pointer;
    display: flex; /* Enable flex layout for button */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    
    
}
/* .copy-button :hover{
 background-color: grey;
 border-radius: 5px;
} */


.success-box-1 {
    width: 20vw;
    height: 19vh;
    padding:17px;
    flex-shrink: 0;
    border-radius: 19px;
    margin:6vh 0 4vh 0;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 30px;
    border: 1px solid var(--glass-card-border, rgba(255, 255, 255, 0.40));
    background: rgba(83, 83, 83, 0.27);
    box-shadow: 42px 48px 63px 0px rgba(38, 38, 38, 0.15);
    backdrop-filter: blur(33.650001525878906px);
}

.msg {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.72px;
    line-height: normal;
}
.val {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.9vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.8px;
    margin-top: 0.01vh;
} 
.tick-image {
    width: 15vw;
    height: auto;
    flex-shrink: 0;
}
.wrong-image {
    width: 4vw;
    height: auto;
    margin-top: 3vh;
}
.container {
    background-color: rgba(255, 255, 255, 0.8);
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
  
h1.technica {
    width: 23vw;
    height: 6vh;
    color: #FFF;
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 3.5vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 4.6px;
    margin-left: 3vw;
    margin-bottom: 1vh;
}

h1.technica .nica {
    color: #27A5EF;
}

h2 {
    font-size: 24px;
    color: #333;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
}
.l1 {
    font-size: 1.8vh; 
    margin-bottom: 0.1vh;
    margin-left: 6.3vw;
    margin-right: 6.3vw;
    text-align: left;
    color: #FFF; 
    font-family: Poppins, sans-serif; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0;
}
.l2 {
    font-size: 2.2vh; 
    margin-bottom: 0.1vh;
    margin-left: 3.5vw;
    margin-right: 6.3vw;
    text-align: left;
    color: #FFF; 
    font-family: Poppins, sans-serif; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0;
    letter-spacing: -0.403px;
}
label {
    font-size: 1.8vh; 
    margin-bottom: 1.4vh;
    margin-left: 6.3vw;
    margin-right: 6.3vw;
    text-align: left;
    color: #FFF; 
    font-family: Poppins, sans-serif; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0;
}
.join-label {
    font-size: 1.85vh; 
    margin-right: 5vw;
    text-align: left;
    color: #FFF; 
    font-family: Poppins, sans-serif; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0;
    margin-left:4.2vw;  

  }
.join {
    width: 40vw; 
    height: 2.8vh; 
    padding: 1.4vh;
    border-radius: 10px; 
    opacity: 1;
    color: #8F8F8F;
    font-family: Poppins, sans-serif;
    font-size: 1.85vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: rgba(217, 217, 217, 0.05);
    border:  1.277px solid rgba(255, 255, 255, 0.60);
    max-width:500px;
    min-width: 300px;
    margin-top: 2.5vh;
    margin-bottom: 4vh;
    margin-left: 4.2vw;
    margin-right: 6.3vw;
}
select{
    width: 40vw; 
    height: 6vh; 
    padding: 1.4vh;
    border-radius: 10px; 
    opacity: 0.3;
    font-family: Poppins, sans-serif;
    font-size: 1.85vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width:520px;
    min-width: 300px;
    margin-bottom: 1.6vh;
    margin-left: 4.2vw;
    margin-right: 6.3vw;
    background: rgba(217, 217, 217, 0.05);
    color: #8F8F8F;
    border:  1.277px solid rgba(255, 255, 255, 0.60);
}
select::placeholder {
    color: rgb(225, 219, 219);
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.selectoption {
    color: rgb(225, 219, 219);
    background-color: rgba(1, 2, 13,1);;    
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.reviewtitle {
    color: #FFF;
    font-family: Michroma-Regular, sans-serif;
    font-size: 6vh;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 3vh;
    margin-bottom: 4vh;
}
.revlab {
    font-size: 1.9vh; 
    margin-bottom: 1vh;
    margin-left: 0vw;
    margin-right: 6.3vw;
    text-align: left;
    color: #FFF; 
    font-family: Poppins, sans-serif; 
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex-shrink: 0;
    letter-spacing: -0.484px;
}
.revinput{
    width: 95%;
    height: 2vh;
    padding: 1.6vh;
    margin-bottom: 2.5vh;
    border-radius: 10px;
    margin-left: 0vw;
    margin-right: 6.3vw;
    min-width: 200px;
    opacity: 1;
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: rgba(217, 217, 217, 0.05);
    color: #8F8F8F;
    border:  1.277px solid rgba(255, 255, 255, 0.60);
    
}
.revinput1{
    width: 25vw;
    min-width: 150px;
    margin-right:5px;
    display: inline-block;
}
.revinput2{
    width: 31.5vw;
    min-width: 150px;
    margin-right:5px;
    display: inline-block;
}
.f1.uploadbtn{
    margin-left:0px;
    margin-right:0;
    margin-top: 0;
    height:5.882vh;
    padding:5px;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.img2{
    height: 40%;
    margin-left: 9px;
}
.othergroup{
    display: flex;
    align-items: center;
}
.revidea{
    width: 95%;
    height: 10vh;
}
.mandatory{
    color:red;
    margin-left: 3px;
}
.uploadbtn{
    width: 25%;
    height: 5.4vh;
    padding: 1.2vh;
    margin:auto 6.3vw 2.5vh 0vw;
    border-radius: 8px;
    border: 1.53px solid #27A5EF;
    background: #27A5EF;
    min-width: 70px;
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.fileupload{
    display:none;
}
.ins{
    color: #FFF;
    font-family: Poppins, sans-serif;
    font-size: 2.5vh;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 3vh 0vw 2vh 0vw;
    text-align: left;
}
.ins1{
    font-size: 2vh;
    font-weight:100;
    margin:2vh 0;
}
.ins1 boldeffect {
    font-weight:900;
}
input::placeholder {
    color: rgb(225, 219, 219);
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.input1 {
    width: 55%;
    height: 2.5vh;
    padding: 1.6vh;
    margin-bottom: 1.6vh;
    border-radius: 10px;
    margin-left: 6.3vw;
    margin-right: 6.3vw;
    min-width: 200px;
    opacity: 1;
    background: rgba(217, 217, 217, 0.05);
    color: #8F8F8F;
    border:  1.277px solid rgba(255, 255, 255, 0.60);
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.input1::placeholder {
    color: rgb(225, 219, 219);
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.input2 {
    width: 66%;
    height: 2.5vh;
    padding: 1.6vh;
    margin-bottom: 1.6vh;
    border:  1.277px solid rgba(255, 255, 255, 0.60);
    border-radius: 10px;
    margin-left: 3.5vw;
    margin-right: 6.3vw;
    min-width: 200px;
    opacity: 1;
    background: rgba(217, 217, 217, 0.05);
    color: #8F8F8F;
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.input2::placeholder {
    color: rgb(225, 219, 219);
    font-family: Poppins, sans-serif;
    font-size: 1.9vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.teaminput {
    width: 400px; 
    height: 15px; 
    padding: 10px;
    margin-bottom: 10px;
    border: 3px solid #27A5EF;
    border-radius: 10px; 
    font-size: 16px;
    margin-left: 88px;
    opacity: 1;
    background: rgba(31, 11, 11, 0.05);
    color: #8F8F8F;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.team-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }
  
.team-box {
    width: 400px;
    height: 15px;
    padding: 10px;
    margin: 10px;
    border: 3px solid #27A5EF;
    border-radius: 10px;
    font-size: 16px;
    opacity: 0.8;
    background: rgba(217, 217, 217, 0.05);
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    cursor: pointer;
    color: #FFF;
    font-style: normal;
    line-height: normal;
}

.flex-links-container {
  display: flex;
  align-items: center;
  margin-bottom:2vh;
  color: #27A5EF; 
  font-family: 'Poppins', sans-serif; 
  font-size: 1.5vh; 
  font-style: normal; 
  font-weight: 300; 
  letter-spacing: -0.306px;
}

.flex-links-container .a1 {
    margin-left: 4.2vw;
    margin-right: 0.3vw;
    color: #27A5EF; 
    text-decoration: none;
    display: flex;
    align-items: center;
}

.flex-links-container .a2 {
    margin-right: 1vw;
    color: #FFF; 
    text-decoration: underline;
    font-weight: 400;
    display: flex;
    align-items: right;
}

.flex-links-container a:hover {
  text-decoration: underline; 
}
.cont2 {
    margin-left: 2.5vw; 
  }

@font-face {
    font-family: 'Baloo Da';
    src: url('./img/Baloo-Regular.ttf') format('truetype'); 
}
@font-face {
    font-family: 'Poppins';
    src: url('./img/Poppins-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Michroma-Regular';
    src: url('./img/Michroma-Regular.ttf') format('truetype'); 
}  
@font-face {
    font-family: 'Michroma-Regular';
    src: url('./img/Michroma-Regular.ttf') format('truetype'); 
}  
.button1 {
    background-color: #fff;
    color: #27A5EF;
    border: 3px solid rgba(255, 255, 255, 0.56);
    border-radius: 10px;
    font-size: 1.1vw;
    cursor: pointer;
    width: 62%;
    height: 5.5vh;
    flex-shrink: 0;
    margin: 3vh auto 3vh auto; 
    font-family: 'Poppins',sans-serif; 
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center; 
    display: flex; 
    align-items: center;
    justify-content: center;
}

.submitbtn {
    width: 100%;
    background-color: #fff;
    color: #27A5EF;
    border: 3px solid rgba(255, 255, 255, 0.56);
    border-radius: 10px;
    font-size: 1.3vw;
    cursor: pointer;
    height: 5.5vh;
    flex-shrink: 0;
    margin: 3vh auto 5vh auto; 
    font-family: 'Poppins',sans-serif; 
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center; 
    display: flex; 
    align-items: center;
    justify-content: center;
    letter-spacing: -0.484px;
}
.button11 {
    background-color: #fff;
    color: #27A5EF;
    border: 3px solid rgba(255, 255, 255, 0.56);
    border-radius: 10px;
    font-size: 1.1vw;
    cursor: pointer;
    width: 73%;
    height: 5vh;
    flex-shrink: 0;
    margin: 3.5vh auto 2vh auto; 
    font-family: 'Poppins',sans-serif; 
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center; 
    display: flex; 
    align-items: center;
    justify-content: center;
}

.button2 {
    background-color: #fff;
    color: #27A5EF;
    border: 3px solid rgba(255, 255, 255, 0.56);
    border-radius: 10px;
    font-size: 1.1vw;
    cursor: pointer;
    width: 15vw; 
    height: 6.7vh;
    flex-shrink: 0;
    margin: 2vh auto 6vh auto; 
    font-family: 'Poppins',sans-serif; 
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.52px;
    line-height: normal;
    text-align: center; 
    display: flex; 
    align-items: center;
    justify-content: center;
}
.button3 {  
    background: rgba(39, 165, 239, 0.10);
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
    width: 18vw; 
    height: 7vh;
    max-width:250px;
    flex-shrink: 0;
    margin: auto 0.5vw 3vh 0.5vw; 
    font-family: 'Poppins',sans-serif; 
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.532px;
    line-height: normal;
    text-align: center; 
    display: flex; 
    align-items: center;
    justify-content: center;
}

.button4 {
    color: #fff;
    padding:0.9%;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height:auto;
    width:6vw;
    border-radius: 20px;
    border: 2px solid var(--main-card-border, rgba(255, 255, 255, 0.20));
    background: rgba(114, 114, 114, 0.20);
    box-shadow: 14px 16px 21px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    margin-top:3%;
    margin-right: 2%;
}
.button6 {
    color: #fff;
    padding:0.9%;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height:10vh;
    width:6vw;
    border-radius: 20px;
    border: 2px solid var(--main-card-border, rgba(255, 255, 255, 0.20));
    background: rgba(114, 114, 114, 0.20);
    box-shadow: 14px 16px 21px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    margin-top:3%;
    margin-right: 2%;
}
.button111 {
    color: #fff;
    padding:0.9%;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height:10vh;
    width:10vw;
    border-radius: 20px;
    border: 2px solid var(--main-card-border, rgba(255, 255, 255, 0.20));
    background: rgba(114, 114, 114, 0.20);
    box-shadow: 14px 16px 21px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    margin-top:3%;
    margin-right: 2%;
}


.button-container {
    margin: 0 3vw;
    display: flex;
    justify-content:space-evenly; 
    align-items: center;
}

button:hover {
    text-decoration: underline;
}
.button3:hover {
    text-decoration: underline;
  }


@media (max-width: 800px) {
    
    .button1 {
        font-size: 2.5vh; 
        width: 45%; 
        max-width: 250px; 
        margin: 2vh auto 3vh auto; 
    }
   
    .submitbtn {
        font-size: 2.5vh; 
        width: 60%; 
        max-width: 250px; 
        margin: 2vh auto 3vh auto; 
    }
    .backbtn {
        font-size: 2vh; 
        width: 28vw; 
        height:4.5vh;
        max-width: 250px; 
        margin: 2vh auto 1vh auto; 
    }
    .button11 {
        font-size: 2vh; 
        width: 70vw; 
        max-width: 250px; 
        margin: 2vh auto; 
    }
    .input1 {
        width: 75%; 
        max-width: 250px; 
        min-width: 100px;
        margin-left: 8%; 
        margin-right: 8%;
    }
    .input2 {
        width: 70vw; 
        max-width: 220px; 
        min-width: 80px;
        margin-left: 6vw; 
    }
    .l1 {
        font-size: 1.8vh; 
        margin-left: 8%;
        margin-right: 8%;
    }
    .l2 {
        font-size: 2vh; 
        margin-left: 6vw;
    }
    label {
        font-size: 1.8vh; 
        margin-left: 8%;
        margin-right: 8%;
    }
    h1.technica {
        font-size: 2.4vh; 
    }
    .sign-up-text {
        font-size: 4vh;
        margin-top: 4vh;
    }
    .reviewtitle {
        font-size: 5vh;
        margin-top: 3vh;
        margin-bottom: 2vh;
    }
    .revinput{
        width: 75%;
        max-width: 250px;
        min-width: 100px;
        margin-left: 0%;
        margin-right: 8%;
    }
    .revinput1{
        width: 65%;
        max-width: 250px;
        min-width: 100px;
        margin-right: 2%;
    }
    .uploadbtn{
        width: 20vw;
        padding: 1vh;
        min-width: 90px;
    }
    .f1.uploadbtn{
        font-size:2.2vw;
        min-width: 90px;
        margin-left:2px;
        margin-right:10px;
    }
    .ins{
        font-size: 3.5vw;
    }
    .ins1{
        font-size: 3vw;
    }
    .sign-in-text {
        font-size: 2.5vh;
        margin-top: 6vh;
    }
    .rectangle {
        width: 80vw;
        height: 88vh;
        max-width: 330px;
        min-width: 100px;
    }
    .rect {
        width: 70vw;
        height: 50vh;
        max-width: 330px;
        min-width: 100px;
    }
    .rect2 {
        width: 90vw;        
        height: 40vh;
        max-width: 330px;
        min-width: 80px;
        overflow: auto;
    
    }
    .rect3 {
        width: 90vw;        
        height: 60vh;
        max-width: 330px;
        min-width: 80px;
        overflow: auto; 
    }
    .rect6 {
        width: 70vw;
        height: fit-content;
        padding-top: 4vw;
        padding-bottom: 4vw;
        max-width: 900px;
        min-width: 100px;
        border-radius: 35px;
        
    }
    .reviewrect {
        width: 87vw;
        margin: 3vh auto 3vh auto;
        overflow: auto;
        padding: 2vh 2vw;
    }
    .reviewrect2 {
        overflow: auto;
        width: 75vw;
        margin: 5vh auto 5vh auto;
        padding: 5vh 4vw;
    }
    .reviewtxt {
        font-size: 5vh;
    }
    .teamnamebox{
        width: 87vw;
        height:auto;
        margin: 10vh auto 2vh auto;
        padding: 1.8vh 0vw;
    }
    .teamnamebox h1{
        font-size:3.4vh;
    }
    .teamdetailbox{
        width: 87vw;
        padding: auto 4vw;
        margin: 1vh auto 5vh auto;
        flex-wrap: wrap;
        flex-direction: initial;
    }
    
    .teammembox{
        width:47%;
        margin:1% auto;
    }
    .teammembox1{
        margin-top:1%;
    }
    .teammembox1{
        margin-bottom:1%;
    }
    .teammemdetail{
        overflow: auto;
    }
    .teammembox h1{
        font-size: 2vh;
    }
    .teammembox h2{
        font-size: 2vh;
    }
    .reviewdesc h1{
        font-size: 4vh;
    }
    .reviewdesc h2{
        font-size: 3vh;
    }
    .reviewdesc h3{
        font-size: 2.5vh;
    }
    .reviewdesc h4{
        font-size: 2vh;
    }
    .reviewtime {
        font-size: 2.5vh;
    }
    .arrow img{
        height:6vh;
    }
    .container1{
        flex-direction:column;
    }
    .left-box{
        height:auto;
    }
    .right-box{
        height: auto;
        width: auto;
    }
    .button4 {
        font-size: 2.1vh; 
        height:3.5vh;
        border-radius: 5px;
        width: 40%; 
        max-width: 150px; 
    }
    .username {
        font-size: 5.5vw;
        margin: 3vh auto 1vh auto;
    }
    .reg-success-text {
        font-size: 3.5vh;
        margin: 3vh 5vw 1vh 5vw;
    }
    .errortext {
        font-size: 2.7vh;
    }
    .errormsg {
        font-size: 1.7vh;
    }
    .rect2 h1 {
        font-size: 3.2vh;
    }
    .scroll{
        overflow-x: hidden;
    }
    .success-box {
        width: 35vw;
        height: 10vh;
        flex-shrink: 0;
        margin:4vh 0 4vh 0;
        border-radius: 10px;
        min-width: 100px;
        max-width:150px;
    }
    
    .msg {
        font-size: 1.5vh;
    }
      
    .val {
        font-size: 1.5vh;
    } 
    .tick-image {
        width: 15vh;
    }
    .wrong-image {
        width: 4vh;
    }
    .button2 {
        font-size: 1.5vh;
        width: 35vw;
        height: 4vh;
        margin: 0vh auto 0vh auto; 
        min-width: 100px;
        max-width:150px;
    }
    
    .flex-links-container {
        font-size: 1.5vh; 
    }
  
    .flex-links-container .a1 {
        margin-left: 7vw;
    }
    .join-text {
        font-size: 8vw;
        margin-top: 5vh;
        margin-left: 3%;
    }
    .rect4 {
        overflow: auto;
        border-radius: 30px;
    }
    .button3 {
        font-size: 3.5vw;
        width: 55vw; 
        height: 6vh;
        margin: 1vw 0 1vw 0;
        max-width: 400px;
    }
    .button-container {
        flex-direction: column;
        margin-bottom: 4vw;
        margin-top: 2vw;
    }
    .join-label {
        font-size: 1.85vh; 
        margin-right: 20vw;
        margin-left: 8%;
      }
    .join {
        height: 2.8vh;
        width: 80vw; 
        max-width: 250px; 
        min-width: 80px;
        margin-bottom: 2.2vh;
        margin-left: 8%; 
        margin-right: 8%;
    }
    select{
        height: 6vh;
        width: 80vw; 
        max-width: 270px; 
        min-width: 80px;
        margin-bottom: 2.2vh;
        margin-left: 8%; 
        margin-right: 8%;
    }
    .rect5 {
        width: 75vw;
        max-width: 1050px;
        height: auto;
        margin: 2vh auto 5vh auto;
        padding-bottom: 5vh;
    }
    .card{
        height:20vh;
        width:85%;
        border-radius: 25px;
        margin: 1vh auto;
        padding:1.5vh 0.5vw;
    }
    .cardimg{
        height: 15vw;
        max-height:200px;
        max-width: 200px;
        margin-right: 1.5vw;
    }
    .teammem {
        margin-left: 60%;
    }
    .memdetails{
        overflow: scroll;
    }
    .teamname {
        font-size:4.5vh;
        margin:6vh 0 3vh 0;
    }
    .teamdetails{
        margin: 0 auto 3vw auto;
    }
    .memname {
        font-size: 5.5vw;
        margin: 3vh auto 1vh auto;
    }
    .detailname {
        font-size: 2.5vw;
    }
    .detailval {
        font-size: 2.5vw;
        margin-bottom:2.5vw;
    }
}

.list{
    list-style: none;
    padding: 0;
    margin: 0;
}


/* For WebKit browsers */
::-webkit-scrollbar {
    width: 0px; /* Width of the scrollbar */
  }


  .button5
{
    color: #fff;
    border: none;
    border-radius: 2px;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height:7vh;
    width:4vw;
    border-radius: 20px;
    border: 2px solid var(--main-card-border, rgba(255, 255, 255, 0.20));
    background: rgba(114, 114, 114, 0.20);
    box-shadow: 14px 16px 21px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(30px);
    margin-top:5%;
    text-decoration: none;
}

a{
    text-decoration: none;
}

.return-back{
    display: flex;
    justify-content: start;
    padding:50px 0px 30px 50px;
}